* {
  box-sizing: border-box;
}

body {
  height: 100%;
  overflow: hidden;
  margin: 0px;
  font-family: "Helvetica Neue", Helvetica, arial, sans-serif, serif, monospace !important;
}

body :focus {
  outline: none;
}

.als {
  align-items: flex-start !important;
}

.alc {
  align-items: center !important;
}

.text-capitalize {
  text-transform: capitalize;
}
.text-lowercase {
  text-transform: lowercase;
}

.txt-field {
  border: 1px solid #eee;
  margin: 0;
  outline: none;
  border-radius: 5px;
  font-family: "Helvetica Neue", Helvetica, arial, sans-serif, serif, monospace;
  padding: 10px 9px;
  font-size: 13px;
  color: #677582;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.txt-field::-moz-placeholder {
  color: #a6a6a9;
}

.txt-field:-ms-input-placeholder {
  color: #a6a6a9;
}

.txt-field::placeholder {
  color: #a6a6a9;
}

.txt-field:-ms-input-placeholder,
.txt-field::-ms-input-placeholder {
  color: #a6a6a9;
}

.txt-field:active:not[readonly] {
  color: #212b36;
  border-color: #212b36;
}

.txt-field.error {
  border-color: red;
  color: #eee;
}

.txt-field.one-input {
  padding-left: 8px;
  width: 30px;
  padding-right: 8px;
  text-align: center;
}

.txt-field.one-input:not(:last-child) {
  margin-right: 3px;
}

.txt-field.with-browser-loy-button {
  padding-right: 42px;
}

.txt-warning {
  outline: none;
  font-family: "Helvetica Neue", Helvetica, arial, sans-serif, serif, monospace;
  padding: 10px 18px;
  font-size: 13px;
  color: #d0020a;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.txt-info {
  font-family: "Helvetica Neue", Helvetica, arial, sans-serif, serif, monospace;
  font-size: 13px;
  color: #677582;
}

.head-title {
  margin: 7px auto 7px auto;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
}

.header-main {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  line-height: 40px;
  font-family: Poppins, arial, sans-serif, serif, monospace;
}

.main-default,
body {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.main-default,
.head-title,
body {
  color: #212b36;
  font-family: "Helvetica Neue", Helvetica, arial, sans-serif, serif, monospace;
}

.main-default {
  text-align: center;
  font-size: 13px;
  border-top: 1px solid #dcdcdc;
  padding-top: 10px;
}

.less-color {
  font-size: 14px;
  color: #848c94;
}

.loy-button {
  cursor: pointer;
  border-radius: 10px;
  padding: 15px 30px;
  font-size: 15px;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  border: 1px solid transparent;
  overflow: hidden;
}

.loy-button.loy-button-sm {
  padding: 10px 16px;
  margin-top: 10px;
}

.loy-button.focus,
.loy-button:focus,
.loy-button:hover {
  text-decoration: none;
}

.loy-button.active,
.loy-button:active {
  outline: 0;
  background-image: none;
}

a.loy-button {
  text-decoration: none;
}

a {
  outline: none;
}

.loy-button-loading,
.loy-button-success {
  position: relative !important;
}

.loy-button-success:before {
  width: 22px !important;
  height: 22px !important;
  border: none !important;
  -webkit-animation: none !important;
  animation: none !important;
}

.loy-button-success:after {
  background-color: inherit !important;
}

.error {
  color: red;
}

.error-text {
  font-size: 13px;
  line-height: 22px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: left;
  width: 100% !important;
  color: red;
}

.loy-button-link-main {
  text-align: left;
  background-color: transparent;
  color: inherit;
  font-weight: inherit;
  text-decoration: underline;
  line-height: inherit;
  font: inherit;
  cursor: pointer;
  border: none;
}

.loy-button-main.loy-button-loading:before {
  width: 20px !important;
  border-top-color: #677582 !important;
  border: 1px solid #fff !important;
  height: 20px !important;
}

.loy-button-main.loy-button-loading:after {
  background-color: inherit !important;
}

.no-outline .loy-button {
  outline: none;
}

.browser-loy-button {
  background-color: #fff;
  height: 35px;
  width: 35px;
  text-align: left;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  position: relative;
  padding: 9px;
}

.browser-loy-button:hover {
  background-color: #fafafa;
  opacity: 1;
}

.browser-loy-button:hover:after {
  opacity: 1;
}

.browser-loy-button:after {
  background-repeat: no-repeat;
  left: 0;
  content: "";
  opacity: 0.5;
  top: 0;
  background-position: 50%;
  bottom: 0;
  right: 0;
  position: absolute;
  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.browser-loy-button:active {
  opacity: 1;
  background-color: #f5f5f5;
}

.browser-loy-button:active:after {
  opacity: 1;
}

.browser-loy-button.history:after {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%238d8d8f' fill-rule='nonzero' clip-rule='evenodd' d='M2 19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H5C3.34315 2 2 3.34315 2 5V19ZM20 19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5C4 4.44772 4.44772 4 5 4H10V12.0111L12.395 12.0112L14.0001 9.86419L15.6051 12.0112H18.0001L18 4H19C19.5523 4 20 4.44772 20 5V19ZM16 4H12V9.33585L14.0001 6.66046L16 9.33571V4Z' /%3E%3C/svg%3E");
}

.browser-loy-button.copy:after {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%238d8d8f' d='M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2z'/%3E%3C/svg%3E");
}

.browser-loy-button.tick-icon {
  opacity: 1;
  pointer-events: none;
}

.browser-loy-button.tick-icon:after {
  opacity: 1 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z' fill='%23eee' fill-rule='non-zero' /%3E%3C/svg%3E");
}

.arrow.right {
  transform: rotate(180deg);
}

.arrow {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%238d8d8f' fill-rule='nonzero' d='M11.78 5.53a.75.75 0 0 0-1.06-1.06l-5 5a.75.75 0 0 0 0 1.06l5 5a.75.75 0 0 0 1.06-1.06L7.31 10l4.47-4.47z'/%3E%3C/svg%3E");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
  height: 23px;
  width: 23px;
}

.tick {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
  height: 23px;
  width: 23px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z' fill='%238d8d8f' fill-rule='non-zero' /%3E%3C/svg%3E");
}

.arrow-loy-button,
.loy-close-widget {
  background-repeat: no-repeat;
  background-position: 50%;
}

.section-table-piece-item:active .arrow,
.section-table-piece-item:hover .arrow {
  opacity: 1;
}

.ba-loy-banner .section-top-wrapper .arrow-loy-button {
  display: none;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill-rule='non-zero' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z' fill='%23fff' /%3E%3C/svg%3E");
}
.ba-loy-banner .section-top-wrapper.collapsed .arrow-loy-button {
  display: block;
}

.loy-button-success:before {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z' fill='%23eee' fill-rule='non-zero' /%3E%3C/svg%3E");
}

.dob-container {
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
}

.dob-container .day-of-birth .pieces {
  display: inline-flex;
  flex-direction: row;
}

.dob-container .day-of-birth .pieces h4:nth-of-type(2) {
  padding-left: 45px;
}

.dob-container .day-of-birth .pieces h4 {
  font-weight: 300;
}

.dob-container .day-of-birth .txt-field.one-input:nth-of-type(2) {
  margin-right: 15px !important;
}

.txt-field.with-browser-loy-button + .browser-loy-button {
  border-radius: 7px;
}

.panel-top-wrapper {
  padding: 8px 0 8px 12px;
  margin-bottom: 8px;
}

.panel-top-wrapper .panel-title {
  padding-right: 13px;
}

.next-reward-title {
  padding: 8px 12px;
}

.section-table-wrapper {
  margin-bottom: 18px;
  position: relative;
}

.section-table-wrapper.unborderable {
  margin-bottom: 12px;
}

.section-table-wrapper.unborderable:after {
  display: none;
}

.section-table-wrapper.unhoverable .section-table-piece-item {
  cursor: auto !important;
}

.section-table-wrapper.scrollable {
  overflow-y: auto;
}

div.section-table-piece-item.bottom-full-border {
  margin-bottom: 20px !important;
  padding-bottom: 20px;
}

.bottom-full-border.fit-window:after {
  width: calc(100% + 10px);
}

.bottom-full-border:after,
.section-table-wrapper:after {
  content: "";
  background-color: #dcdcdc;
  left: -14px;
  width: calc(100% + 25px);
  bottom: -8.5px;
  height: 1px;
  position: absolute;
}

.section-table-wrapper:last-of-type:not(.has-bottom-border),
.m-bottom0 {
  margin-bottom: 0;
}

.section-table-wrapper:last-of-type:not(.has-bottom-border):after {
  display: none;
}

.section-table-piece-wrapper {
  position: relative;
}

.section-table-piece-wrapper:after {
  content: "";
  position: absolute;
  bottom: -9px;
  background-color: #dcdcdc;
  height: 1px;
  width: 100%;
}

.section-table-piece-wrapper.with-icon:after {
  left: 0px;
}

.section-table-piece-wrapper.with-secondary-button {
  padding: 0px 5px;
}

.section-table-wrapper.points-section {
  padding-top: 10px;
}

.section-table-piece-wrapper.with-secondary-button:after {
  display: none;
}

.section-table-piece-wrapper:last-of-type .section-table-piece-item {
  margin-bottom: 0;
}

.section-table-piece-wrapper:last-of-type:after {
  display: none;
}

.section-table-piece-item {
  text-align: left;
  font: inherit;
  cursor: pointer;
  position: relative;
  margin-bottom: 12px;
  padding: 4px 12px;
  width: 100%;
  background-color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.section-table-piece-item.unhoverable {
  cursor: auto;
}

.section-table-piece-item.unhoverable:active,
.section-table-piece-item.unhoverable:hover {
  background-color: inherit !important;
}

.section-table-piece-item.unborderable:after {
  display: none;
}

.section-table-piece-item:hover {
  background-color: #f8f8f8;
}

.section-table-piece-item:active {
  background-color: #fafafa;
}

.section-table-piece-item .section-table-piece-detail-items {
  align-items: center;
  display: flex;
}

.section-table-piece-item .section-table-piece-detail-items.half-table {
  width: 48%;
  text-align: center;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 10px;
}

.half-weight {
  font-weight: 500;
}

.section-current-tier-show .li-img,
.section-table-piece-item .section-table-piece-detail-items .li-img {
  padding: 6px;
  margin-right: 10px;
  height: 44px;
  width: 44px;
  min-width: 44px;
}

.section-table-piece-item .loy-button {
  flex-shrink: 0;
  min-width: 80px;
  margin-left: 8px;
}

.section-table-piece-item .loy-button.points-activity-rule-action-button {
  width: 96px;
  max-width: 96px;
  min-width: 96px;
}

.section-table-piece-item .loy-button.prods-btn {
  min-width: 90px;
}

.full-width {
  width: 100% !important;
}

.loy-button-loading,
.loy-button-success,
.ajax-interceptor {
  position: inherit;
  overflow: hidden;
  pointer-events: none;
}

.loy-button-loading:before,
.loy-button-success:before,
.ajax-interceptor:before {
  content: "";
  background-color: inherit;
  width: 30px;
  height: 30px;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  position: absolute;
  border-radius: 50%;
  font-size: 5px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 219, 219, 0.2);
  border-right: 1.1em solid rgba(219, 219, 219, 0.2);
  border-bottom: 1.1em solid rgba(219, 219, 219, 0.2);
  border-left: 1.1em solid #dbdbdb;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.6s infinite linear;
  animation: load8 0.6s infinite linear;
}

.loy-button-loading:after,
.loy-button-success:after,
.ajax-interceptor:after {
  left: 0 !important;
  background-color: #fff;
  height: 100% !important;
  width: 100% !important;
  z-index: 0;
  content: "";
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.spin-wrapper {
  position: fixed;
  height: 100vh;
  z-index: 1;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ajax-loader-cover {
  position: absolute;
  height: 100%;
  z-index: 1;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ajax-loader-out-active,
.hide-ajax-spin {
  -webkit-animation: ajaxLoaderExit 0.25s ease-in-out;
  animation: ajaxLoaderExit 0.25s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ajax-loader-out-done,
.ajax-loader-hidden {
  display: none !important;
  -webkit-animation: none !important;
  animation: none !important;
}

@-webkit-keyframes ajaxLoaderExit {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  to {
    opacity: 0;
    display: none;
  }
}

@keyframes ajaxLoaderExit {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  to {
    opacity: 0;
    display: none;
  }
}

.panel-desc {
  padding: 0 12px;
  margin-bottom: 20px;
  line-height: 20px;
  color: #737376;
}

#baLoySectionWrapper .main-default.sign-in-text {
  color: #737376;
}

.panel-padding {
  padding: 0 12px;
}

.panel-desc ul {
  margin: 0;
  padding-left: 20px;
}

.section-top-wrapper {
  max-height: 0;
  height: 0;
  width: 100%;
  overflow: visible;
  color: #fff;
  outline: none;
}

.section-top-bar {
  color: #fff;
  min-height: 60px;
  width: 100%;
  z-index: 10;
  padding: 16px 10px;
  position: fixed;
}

.section-top-bar .head-container {
  display: flex !important;
  opacity: 0;
  transition: opacity 0.15s ease-in;
}

.section-top-bar .back-button {
  height: 20px;
  width: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-right: 8px;
}

.section-back-img {
  cursor: pointer;
  background-color: transparent;
  border: none;
  height: 32px;
  width: 32px;
  background-size: 22px;
}

.section-back-img:hover,
.loy-close-widget:hover {
  background-color: hsla(0, 0%, 99%, 0.07);
}

.section-back-img:active {
  background-color: hsla(0, 0%, 99%, 0.11);
}

.section-top-wrapper .section-open-top-panel {
  padding: 22px;
  position: relative;
  color: inherit;
}

.section-top-wrapper .section-open-top-panel .back-img {
  margin-bottom: 12px;
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 37px;
  width: 37px;
}

.section-top-wrapper .section-open-top-panel .header-secondary,
.points-expiry-desc {
  font-weight: 400;
  color: inherit;
}

.points-expiry-desc {
  font-size: 14px;
}

.section-top-wrapper.open-top-panel + .panel-section {
  padding-top: 160px;
}

.small-notice .section-top-wrapper.open-top-panel + .panel-section {
  padding-top: 132px;
}

.panel-section.sort-panel-enabled {
  display: grid;
}

#baLoySectionWrapper .panel-section.sort-panel-enabled .section-panel-wrapper {
  height: max-content;
}

.sort-panel-enabled > div {
  display: flex;
  flex-direction: column;
}

.section-top-wrapper.collapsed .section-open-top-panel {
  z-index: 1;
}

.section-top-wrapper.collapsed .section-top-bar .head-container {
  display: flex !important;
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

.section-top-wrapper.collapsed .section-open-top-panel .header-content {
  transition: opacity 0.05s ease;
  opacity: 0 !important;
}

.section-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  font-size: 13px;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 14px 0 rgba(0, 0, 0, 0.05);
  z-index: 3;
  background: #fff;
  position: fixed;
}

.section-bottom .pwrd {
  color: #a2a2a2;
}

.section-bottom .member-signup-footer-loy-button {
  text-align: center;
  position: absolute;
  margin: 0 auto;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
  -webkit-animation: faderOn 0.3s ease-in-out;
  animation: faderOn 0.3s ease-in-out;
  left: 0;
  right: 0;
  top: -65px;
  width: 65%;
}

.section-bottom .account-login {
  padding: 10px;
  text-align: center;
}

.central-body-wrapper .default-page-wrapper > .section-panel-wrapper {
  -webkit-animation: blurOutAllSection 0.2s ease-in-out;
  animation: blurOutAllSection 0.2s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transform: translate(0);
}

.central-body-wrapper
  .default-page-wrapper
  > .section-panel-wrapper:first-child {
  -webkit-animation: blurOutSection 0.25s ease-in-out;
  animation: blurOutSection 0.25s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}

.central-body-wrapper
  .default-page-wrapper
  > .section-panel-wrapper:nth-child(2) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.central-body-wrapper
  .default-page-wrapper
  > .section-panel-wrapper:nth-child(4) {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.central-body-wrapper
  .default-page-wrapper
  > .section-panel-wrapper:nth-child(3) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.central-body-wrapper .section-bottom {
  bottom: 0;
}

@keyframes blurOutAllSection {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes blurOutAllSection {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes blurOutSection {
  0% {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes blurOutSection {
  0% {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.panel-section {
  padding: 80px 20px 0 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 136px;
  height: 100%;
}

.panel-section::-webkit-scrollbar {
  display: none;
}

.panel-section .default-page-wrapper {
  min-height: calc(100% + 88px);
}

.panel-section .default-page-wrapper .head-title {
  margin-bottom: 8px !important;
}

.panel-section.ba-loy-footer .default-page-wrapper {
  min-height: calc(100% + 40px);
}

.panel-section.ba-loy-footer .section-deep-panel:last-of-type,
.mb-65 {
  margin-bottom: 65px !important;
}

.panel-section.with-member-signup-footer .section-panel-wrapper:last-of-type,
.panel-section.with-member-signup-footer .section-deep-panel:last-of-type {
  margin-bottom: 126px;
}

.small-notice.section-top-wrapper .section-open-top-panel {
  height: 230px;
}

.small-notice.section-top-wrapper.open-top-panel + .panel-section {
  padding-top: 132px;
}

.small-notice.section-top-wrapper.open-top-panel
  + .panel-section.points-expiry-enabled:not(.brand-icon-enabled),
.small-notice.section-top-wrapper.open-top-panel
  + .panel-section.brand-icon-enabled:not(.points-expiry-enabled) {
  padding-top: 162px;
}

.small-notice.section-top-wrapper.open-top-panel
  + .panel-section.points-expiry-enabled.brand-icon-enabled {
  padding-top: 192px;
}

.small-notice.section-top-wrapper.open-top-panel .back-img {
  height: 0;
}

.small-notice.section-top-wrapper.collapsed .section-open-top-panel {
  transform: translate3d(0, -100px, 0) !important;
}

.copy-coupon {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  position: relative;
}

.copy-coupon input.text-field {
  font-size: 16px;
}

.copy-coupon .browser-loy-button {
  position: absolute;
  right: 5px;
  border-radius: 15px;
}

.share-button-container {
  padding: 0 12px;
  margin-bottom: 8px;
}

.section-panel-wrapper {
  padding: 20px 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translate(18px);
  position: relative;
  background-color: #fff;
  margin-bottom: 15px;
  -webkit-animation: slideAndFade 0.2s ease;
  animation: slideAndFade 0.2s ease;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 2px 0px, #286ef8 0px 2px 0px 0px inset;
}

.section-panel-wrapper .home-points-section {
  transition: max-height 0.3s ease-in;
  max-height: 173px;
}

.section-panel-wrapper .home-points-section.home-points-section-injected {
  max-height: 500px;
}

.section-panel-wrapper
  .home-points-section.home-points-section-injected
  .section-table-piece-item:first-child {
  padding-top: 0px;
}

.section-panel-wrapper .home-rewards-section {
  transition: max-height 0.3s ease-in;
  max-height: 58px;
}

.section-deep-panel {
  opacity: 0;
  margin-bottom: 12px;
  transform: translate(20px);
  -webkit-animation: slideAndFade 0.25s ease;
  animation: slideAndFade 0.25s ease;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: relative;
  overflow: hidden;
}

.section-deep-panel .logs-loy-button {
  top: 4px;
  right: 4px;
}

.section-deep-panel .section-table-piece-item.loy-button-loading:after,
.section-deep-panel .section-table-piece-item.loy-button-success:after,
.section-deep-panel .section-table-piece-item.ajax-interceptor:after {
  background-color: #fff !important;
}

.section-deep-panel.scrollable {
  overflow-y: auto;
}

.half-opacity {
  opacity: 0.5;
}

.logs-loy-button {
  position: absolute;
  top: 13px;
  right: 13px;
}

.browser-loy-button.history.logs-loy-button.points-logs {
  position: relative;
  height: 25px;
  width: 25px;
  top: auto;
  right: auto;
}

.section-content {
  background: #ffffff;
}

.section-panel-wrapper {
  border-radius: 5px;
  width: 100%;
}

.section-open-top-panel .header-content {
  z-index: 1;
  text-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
}

.section-top-wrapper.collapsed .section-back-img:hover {
  background-color: #ffffff14;
}

.section-top-wrapper.collapsed .section-back-img:active {
  background-color: #ffffff1f;
}

.section-content .other-window-body,
.section-content .other-window-body .section-wrapper {
  height: 100%;
}

.reward-fulfillment-card .copy-loy-button {
  width: 66.66%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
}

.reward-fulfillment-card .panel-desc.panel-tos {
  padding-top: 20px;
}

.progress-bar {
  display: none;
  height: 20px;
  overflow: visible;
}

.progress-bar path:first-of-type {
  stroke: #e6e6e6;
}

.ba-loy-bg-color {
  background: #2932fc;
}

.loy-button-main:hover {
  filter: brightness(0.9);
}

.loy-button-main:active {
  background-color: hsla(239, 75%, 49%, 1);
}

.loy-button-main {
  color: #fff;
  margin-bottom: 15px;
}

.loy-button-secondary {
  background-color: #fff;
  color: inherit;
  margin-bottom: 15px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
}

.loy-button-link-main {
  color: hsla(239, 55%, 64%, 1);
}

.loy-button-link-main:active {
  color: hsla(239, 75%, 49%, 1);
}

a {
  color: #7072d6;
}

.center-align {
  text-align: center !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.multiline-truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.two-line-clamp {
  -webkit-line-clamp: 2;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes faderOn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes faderOn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes slideAndFade {
  0% {
    opacity: 0;
    transform: translate(20px);
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes slideAndFade {
  0% {
    opacity: 0;
    transform: translate(20px);
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@-webkit-keyframes faderOut {
  0% {
    opacity: 0;
    transform: translate(-10px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes faderOut {
  0% {
    opacity: 0;
    transform: translate(-10px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}
.loy-close-widget {
  position: absolute;
  right: 10px;
  top: 5px;
  background-size: 14px !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cpath d='M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z' fill='%23fff' /%3E%3C/svg%3E");
  background-color: #0000;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.buttons-icons-container {
  justify-content: center;
  padding: 8px 12px 8px 12px;
  position: relative;
  display: flex;
}

.buttons-icons-container span {
  padding: 0px;
}
.buttons-icons-item {
  line-height: inherit;
  cursor: pointer;
  border: none;
  padding: 2px;
  background-color: transparent;
  color: inherit;
  font-weight: inherit;
  font: inherit;
  text-align: center;
  width: 76px;
}
.buttons-icons-item:active,
.buttons-icons-item:hover {
  opacity: 1;
  background-color: #fafafa;
}
.buttons-icons-detail-image {
  width: 32px;
  height: 32px;
  margin: auto;
  margin-bottom: 10px;
}
.buttons-icons-detail-title {
  font-size: 13px;
  color: #212b36;
  text-transform: capitalize !important;
}

.member-signup-card-container {
  padding-bottom: 10px;
}

.ba-tooltip {
  position: relative;
}
.ba-tooltip:before {
  content: attr(data-text);
  line-height: normal;
  position: absolute;
  top: 30px;
  left: -28px;
  margin-left: 0px;
  min-width: 75px;
  width: auto;
  padding-top: 3px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 3px;
  border-radius: 5px;
  background: #000;
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: 0.3s opacity;
  z-index: 9;
  font-size: 13px;
  text-transform: capitalize;
  display: none;
}
.ba-tooltip:after {
  content: "";
  position: absolute;
  left: 30%;
  transform: translateY(10px);
  top: 9px;
  border: 6px solid #000;
  border-color: #0000 #0000 #000 #0000;
  opacity: 0;
  transition: 0.3s;
  display: none;
}
.ba-tooltip:hover:before,
.ba-tooltip:hover:after {
  opacity: 1;
  display: block;
}

.loy-copy-success:before {
  content: "";
  background-color: inherit;
  width: 30px;
  height: 25px;
  z-index: 1;
  top: -5;
  left: 0;
  right: -5px;
  bottom: 0;
  margin: auto;
  display: block;
  position: absolute;
  opacity: 0.7;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z' fill='%238d8d8f' fill-rule='non-zero' /%3E%3C/svg%3E");
}
.flex-column {
  flex-direction: column;
  align-items: center;
}
.vip_rewards,
.vip_perks {
  font-weight: 400;
  color: #212b36;
  margin-bottom: 10px;
}
.vip_show_hr {
  border: none;
  border-bottom: 1px solid #e6e6e6;
}
.current-vip-tier-page .loy-button {
  margin-bottom: 15px;
}
.section-current-tier-show {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.section-current-tier-show svg {
  padding: 6px;
  margin-right: 10px;
  height: 44px;
  width: 44px;
}
.m-top20 {
  margin-top: 20px;
}
.p-bottom50 {
  padding-bottom: 50px;
}
.current-vip-tier-page {
  margin-bottom: 100px !important;
}
.pad-l20 {
  padding-left: 20px;
}
.vip-next-tier-desc .panel-desc {
  margin-bottom: 0px;
}
.min-height10 {
  min-height: 10px;
}
